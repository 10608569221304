import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/react-datocms/dist/esm/Image/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/react-datocms/dist/esm/useQuerySubscription/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/react-datocms/dist/esm/useSiteSearch/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/react-datocms/dist/esm/VideoPlayer/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contents/InternalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Media"] */ "/opt/build/repo/src/components/contents/modularBlocks/Media.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slideshow"] */ "/opt/build/repo/src/components/contents/modularBlocks/Slideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/opt/build/repo/src/components/layout/Layout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/products/Products.tsx");
